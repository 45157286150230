<template>
  <div>
    <b-row>
      <b-col md="7" v-if="currentUser.level_akses == 1">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin.filter" @click="setting_coin.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins"
              :fields="setting_coin.fields"
              :filter="setting_coin.filter"
              :filter-included-fields="setting_coin.filterOn"
              :current-page="setting_coin.currentPage"
              :per-page="setting_coin.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin.currentPage"
                  :total-rows="listCoins == null ? 0 : listCoins.length"
                  :per-page="setting_coin.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin ERC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_erc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_erc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_erc.filter" @click="setting_coin_erc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_erc"
              :fields="setting_coin_erc.fields"
              :filter="setting_coin_erc.filter"
              :filter-included-fields="setting_coin_erc.filterOn"
              :current-page="setting_coin_erc.currentPage"
              :per-page="setting_coin_erc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_erc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_erc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_erc.currentPage"
                  :total-rows="listCoins_erc == null ? 0 : listCoins_erc.length"
                  :per-page="setting_coin_erc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin Hotbit BSC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_hotbit_bsc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_hotbit_bsc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_hotbit_bsc.filter" @click="setting_coin_hotbit_bsc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_hotbit_bsc"
              :fields="setting_coin_hotbit_bsc.fields"
              :filter="setting_coin_hotbit_bsc.filter"
              :filter-included-fields="setting_coin_hotbit_bsc.filterOn"
              :current-page="setting_coin_hotbit_bsc.currentPage"
              :per-page="setting_coin_hotbit_bsc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_hotbit_bsc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_hotbit_bsc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_hotbit_bsc.currentPage"
                  :total-rows="listCoins_hotbit_bsc == null ? 0 : listCoins_hotbit_bsc.length"
                  :per-page="setting_coin_hotbit_bsc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin Mexc BSC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_mexc_bsc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_mexc_bsc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_mexc_bsc.filter" @click="setting_coin_mexc_bsc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_mexc_bsc"
              :fields="setting_coin_mexc_bsc.fields"
              :filter="setting_coin_mexc_bsc.filter"
              :filter-included-fields="setting_coin_mexc_bsc.filterOn"
              :current-page="setting_coin_mexc_bsc.currentPage"
              :per-page="setting_coin_mexc_bsc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_mexc_bsc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_mexc_bsc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_mexc_bsc.currentPage"
                  :total-rows="listCoins_mexc_bsc == null ? 0 : listCoins_mexc_bsc.length"
                  :per-page="setting_coin_mexc_bsc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin Mexc POLYGON</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_mexc_polygon(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_mexc_polygon.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_mexc_polygon.filter" @click="setting_coin_mexc_polygon.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_mexc_polygon"
              :fields="setting_coin_mexc_polygon.fields"
              :filter="setting_coin_mexc_polygon.filter"
              :filter-included-fields="setting_coin_mexc_polygon.filterOn"
              :current-page="setting_coin_mexc_polygon.currentPage"
              :per-page="setting_coin_mexc_polygon.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_mexc_polygon(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_mexc_polygon(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_mexc_polygon.currentPage"
                  :total-rows="listCoins_mexc_polygon == null ? 0 : listCoins_mexc_polygon.length"
                  :per-page="setting_coin_mexc_polygon.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin HECO</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_huobi_heco(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_huobi_heco.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_huobi_heco.filter" @click="setting_coin_huobi_heco.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_huobi_heco"
              :fields="setting_coin_huobi_heco.fields"
              :filter="setting_coin_huobi_heco.filter"
              :filter-included-fields="setting_coin_huobi_heco.filterOn"
              :current-page="setting_coin_huobi_heco.currentPage"
              :per-page="setting_coin_huobi_heco.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_huobi_heco(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_huobi_heco(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_huobi_heco.currentPage"
                  :total-rows="listCoins_huobi_heco == null ? 0 : listCoins_huobi_heco.length"
                  :per-page="setting_coin_huobi_heco.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin HUOBI ERC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_huobi_erc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_huobi_erc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_huobi_erc.filter" @click="setting_coin_huobi_erc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_huobi_erc"
              :fields="setting_coin_huobi_erc.fields"
              :filter="setting_coin_huobi_erc.filter"
              :filter-included-fields="setting_coin_huobi_erc.filterOn"
              :current-page="setting_coin_huobi_erc.currentPage"
              :per-page="setting_coin_huobi_erc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_huobi_erc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_huobi_erc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_huobi_erc.currentPage"
                  :total-rows="listCoins_huobi_erc == null ? 0 : listCoins_huobi_erc.length"
                  :per-page="setting_coin_huobi_erc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin HUOBI BSC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_huobi_bsc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_huobi_bsc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_huobi_bsc.filter" @click="setting_coin_huobi_bsc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_huobi_bsc"
              :fields="setting_coin_huobi_bsc.fields"
              :filter="setting_coin_huobi_bsc.filter"
              :filter-included-fields="setting_coin_huobi_bsc.filterOn"
              :current-page="setting_coin_huobi_bsc.currentPage"
              :per-page="setting_coin_huobi_bsc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_huobi_bsc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_huobi_bsc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_huobi_bsc.currentPage"
                  :total-rows="listCoins_huobi_bsc == null ? 0 : listCoins_huobi_bsc.length"
                  :per-page="setting_coin_huobi_bsc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin KUCOIN BSC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_kucoin_bsc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_kucoin_bsc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_kucoin_bsc.filter" @click="setting_coin_kucoin_bsc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_kucoin_bsc"
              :fields="setting_coin_kucoin_bsc.fields"
              :filter="setting_coin_kucoin_bsc.filter"
              :filter-included-fields="setting_coin_kucoin_bsc.filterOn"
              :current-page="setting_coin_kucoin_bsc.currentPage"
              :per-page="setting_coin_kucoin_bsc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_kucoin_bsc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_kucoin_bsc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_kucoin_bsc.currentPage"
                  :total-rows="listCoins_kucoin_bsc == null ? 0 : listCoins_kucoin_bsc.length"
                  :per-page="setting_coin_kucoin_bsc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin KUCOIN ERC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_kucoin_erc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_kucoin_erc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_kucoin_erc.filter" @click="setting_coin_kucoin_erc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_kucoin_erc"
              :fields="setting_coin_kucoin_erc.fields"
              :filter="setting_coin_kucoin_erc.filter"
              :filter-included-fields="setting_coin_kucoin_erc.filterOn"
              :current-page="setting_coin_kucoin_erc.currentPage"
              :per-page="setting_coin_kucoin_erc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_kucoin_erc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_kucoin_erc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_kucoin_erc.currentPage"
                  :total-rows="listCoins_kucoin_erc == null ? 0 : listCoins_kucoin_erc.length"
                  :per-page="setting_coin_kucoin_erc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin OKX ERC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_okx_erc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_okx_erc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_okx_erc.filter" @click="setting_coin_okx_erc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_okx_erc"
              :fields="setting_coin_okx_erc.fields"
              :filter="setting_coin_okx_erc.filter"
              :filter-included-fields="setting_coin_okx_erc.filterOn"
              :current-page="setting_coin_okx_erc.currentPage"
              :per-page="setting_coin_okx_erc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_okx_erc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_okx_erc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_okx_erc.currentPage"
                  :total-rows="listCoins_okx_erc == null ? 0 : listCoins_okx_erc.length"
                  :per-page="setting_coin_okx_erc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin FTX ERC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_ftx_erc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_ftx_erc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_ftx_erc.filter" @click="setting_coin_ftx_erc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_ftx_erc"
              :fields="setting_coin_ftx_erc.fields"
              :filter="setting_coin_ftx_erc.filter"
              :filter-included-fields="setting_coin_ftx_erc.filterOn"
              :current-page="setting_coin_ftx_erc.currentPage"
              :per-page="setting_coin_ftx_erc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_ftx_erc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_ftx_erc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_ftx_erc.currentPage"
                  :total-rows="listCoins_ftx_erc == null ? 0 : listCoins_ftx_erc.length"
                  :per-page="setting_coin_ftx_erc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col :md="currentUser.level_akses == 1 ? 5 : 12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting App</h3>
            </div>
          </div>
          <div class="card-body">
            <b-form-group v-if="is_admin" label="Link BSD" label-for="setting_url_coin" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_url_coin" v-model="setting_url_coin" type="text" placeholder="Url"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_admin" label="Link ERC" label-for="setting_url_coin_erc" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_url_coin_erc" v-model="setting_url_coin_erc" type="text" placeholder="Url"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_admin" label="Link HECO" label-for="setting_url_coin_huobi_heco" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_url_coin_huobi_heco" v-model="setting_url_coin_huobi_heco" type="text" placeholder="Url"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_admin" label="BUSD Address (BSC)" label-for="setting_busd_address" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_busd_address" v-model="setting_busd_address" type="text" placeholder="Token address BUSD"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_admin" label="USDT Address (ERC)" label-for="setting_usdt_address" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_usdt_address" v-model="setting_usdt_address" type="text" placeholder="Token address USDT"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_admin" label="HUSD Address (HECO)" label-for="setting_husd_address" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_husd_address" v-model="setting_husd_address" type="text" placeholder="Token address HUSD"></b-form-input>
            </b-form-group>
            <b-form-group label="Timeout Request" label-for="timeout_request" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input id="timeout_request" v-model="setting_timeout_request" type="number" placeholder="Timeout Request"></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Import / Export Setting</h3>
            </div>
          </div>
          <div class="card-body">
            <h4>Import</h4>
            <b-form-group label="Import" label-for="setting_import" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-file id="setting_import" placeholder="Url" @change="onFileChange"></b-form-file>
            </b-form-group>
            <b-form-group label="" label-for="setting_import_btn" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-button @click="importSetting()" variant="primary" id="setting_import_btn"><b-icon-download /> Import</b-button>
            </b-form-group>
            <h4>Export</h4>
            <b-form-group label="" label-for="setting_export_btn" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-checkbox v-model="export_bsc">Binance BSC</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_erc">Binance ERC</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_hotbit_bsc">Hotbit BSC</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_mexc_bsc">MEXC BSC</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_mexc_polygon">MEXC POLYGON</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_huobi_heco">Huobi HECO</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_huobi_erc">Huobi ERC</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_huobi_bsc">Huobi BSC</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_kucoin_bsc">Kucoin BSC</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_kucoin_erc">Kucoin ERC</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_okx_erc">OKX ERC</b-checkbox>
              <b-checkbox v-if="is_admin" v-model="export_ftx_erc">FTX ERC</b-checkbox>
              <hr />
              <b-button @click="exportSetting()" variant="primary" id="setting_export_btn"><b-icon-upload /> Export</b-button>
              <b-button class="ml-2" :disabled="!is_admin" v-if="is_admin" @click="exportSettingRaw()" variant="primary" id="setting_export_btn"><b-icon-upload /> Export (Tanpa enkripsi)</b-button>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- modals -->
    <b-modal ref="modal-form-coin" v-bind:title="form_coin.edit == false ? 'Edit Coin' : 'Add Coin'" @ok.prevent="simpanCoin()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin.desimal" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-erc" v-bind:title="form_coin_erc.edit == false ? 'Edit Coin ERC' : 'Add Coin ERC'" @ok.prevent="simpanCoin_erc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_erc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_erc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_erc.desimal" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-hotbit_bsc" v-bind:title="form_coin_hotbit_bsc.edit == false ? 'Edit Coin HOTBIT BSC' : 'Add Coin HOTBIT BSC'" @ok.prevent="simpanCoin_hotbit_bsc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_hotbit_bsc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_hotbit_bsc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_hotbit_bsc.desimal" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-mexc_bsc" v-bind:title="form_coin_mexc_bsc.edit == false ? 'Edit Coin MEXC BSC' : 'Add Coin MEXC BSC'" @ok.prevent="simpanCoin_mexc_bsc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_mexc_bsc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_mexc_bsc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_mexc_bsc.desimal" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_offset_pnl" label="Offset PNL:" label-for="input_offset_pnl">
          <b-form-input id="input_offset_pnl" type="number" placeholder="Offset PNL %" v-model="form_coin_mexc_bsc.offset_pnl" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-mexc_polygon" v-bind:title="form_coin_mexc_polygon.edit == false ? 'Edit Coin MEXC POLYGON' : 'Add Coin MEXC POLYGON'" @ok.prevent="simpanCoin_mexc_polygon()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_mexc_polygon.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_mexc_polygon.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_mexc_polygon.desimal" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_offset_pnl" label="Offset PNL:" label-for="input_offspolygonet_pnl">
          <b-form-input id="input_offset_pnl" type="number" placeholder="Offset PNL %" v-model="form_coin_mexc_polygon.offset_pnl" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-huobi_heco" v-bind:title="form_coin_huobi_heco.edit == false ? 'Edit Coin ERC' : 'Add Coin ERC'" @ok.prevent="simpanCoin_huobi_heco()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_huobi_heco.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_huobi_heco.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_huobi_heco.desimal" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-huobi_erc" v-bind:title="form_coin_huobi_erc.edit == false ? 'Edit Coin HUOBI ERC' : 'Add Coin HUOBI ERC'" @ok.prevent="simpanCoin_huobi_erc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_huobi_erc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_huobi_erc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_huobi_erc.desimal" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-huobi_bsc" v-bind:title="form_coin_huobi_bsc.edit == false ? 'Edit Coin HUOBI BSC' : 'Add Coin HUOBI BSC'" @ok.prevent="simpanCoin_huobi_bsc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_huobi_bsc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_huobi_bsc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_huobi_bsc.desimal" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_offset_pnl" label="Offset PNL:" label-for="input_offset_pnl">
          <b-form-input id="input_offset_pnl" type="number" placeholder="Offset PNL %" v-model="form_coin_huobi_bsc.offset_pnl" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-kucoin_bsc" v-bind:title="form_coin_kucoin_bsc.edit == false ? 'Edit Coin KUCOIN BSC' : 'Add Coin KUCOIN BSC'" @ok.prevent="simpanCoin_kucoin_bsc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_kucoin_bsc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_kucoin_bsc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_kucoin_bsc.desimal" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_offset_pnl" label="Offset PNL:" label-for="input_offset_pnl">
          <b-form-input id="input_offset_pnl" type="number" placeholder="Offset PNL %" v-model="form_coin_kucoin_bsc.offset_pnl" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-kucoin_erc" v-bind:title="form_coin_kucoin_erc.edit == false ? 'Edit Coin KUCOIN ERC' : 'Add Coin KUCOIN ERC'" @ok.prevent="simpanCoin_kucoin_erc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_kucoin_erc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_kucoin_erc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_kucoin_erc.desimal" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_offset_pnl" label="Offset PNL:" label-for="input_offset_pnl">
          <b-form-input id="input_offset_pnl" type="number" placeholder="Offset PNL %" v-model="form_coin_kucoin_erc.offset_pnl" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-okx_erc" v-bind:title="form_coin_okx_erc.edit == false ? 'Edit Coin OKX ERC' : 'Add Coin OKX ERC'" @ok.prevent="simpanCoin_okx_erc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_okx_erc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_okx_erc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_okx_erc.desimal" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_offset_pnl" label="Offset PNL:" label-for="input_offset_pnl">
          <b-form-input id="input_offset_pnl" type="number" placeholder="Offset PNL %" v-model="form_coin_okx_erc.offset_pnl" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-ftx_erc" v-bind:title="form_coin_ftx_erc.edit == false ? 'Edit Coin FTX ERC' : 'Add Coin FTX ERC'" @ok.prevent="simpanCoin_ftx_erc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_ftx_erc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_ftx_erc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_ftx_erc.desimal" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_offset_pnl" label="Offset PNL:" label-for="input_offset_pnl">
          <b-form-input id="input_offset_pnl" type="number" placeholder="Offset PNL %" v-model="form_coin_ftx_erc.offset_pnl" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import download from "downloadjs";
import CryptoService from "@/core/services/crypto.service";

export default {
  data() {
    return {
      setting_coin: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_erc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_hotbit_bsc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_mexc_bsc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "offset_pnl",
            label: "Offset",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_mexc_polygon: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "offset_pnl",
            label: "Offset",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_huobi_heco: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_huobi_erc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_huobi_bsc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "offset_pnl",
            label: "Offset",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_kucoin_bsc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "offset_pnl",
            label: "Offset",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_kucoin_erc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "offset_pnl",
            label: "Offset",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_okx_erc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "offset_pnl",
            label: "Offset",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_ftx_erc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "offset_pnl",
            label: "Offset",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      form_coin: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
      },
      form_coin_erc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
      },
      form_coin_hotbit_bsc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
      },
      form_coin_mexc_bsc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
        offset_pnl: 0,
      },
      form_coin_mexc_polygon: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
        offset_pnl: 0,
      },
      form_coin_huobi_heco: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
      },
      form_coin_huobi_erc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
      },
      form_coin_huobi_bsc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
        offset_pnl: 0,
      },
      form_coin_kucoin_bsc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
        offset_pnl: 0,
      },
      form_coin_kucoin_erc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
        offset_pnl: 0,
      },
      form_coin_okx_erc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
        offset_pnl: 0,
      },
      form_coin_ftx_erc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
        offset_pnl: 0,
      },
      imported_setting: null,
      is_admin: true,
      export_bsc: true,
      export_erc: false,
      export_hotbit_bsc: false,
      export_mexc_bsc: false,
      export_mexc_polygon: false,
      export_huobi_heco: false,
      export_huobi_erc: false,
      export_huobi_bsc: false,
      export_kucoin_bsc: false,
      export_kucoin_erc: false,
      export_okx_erc: false,
      export_ftx_erc: false,
    };
  },
  computed: {
    ...mapGetters([
      "listCoins",
      "listCoins_erc",
      "listCoins_huobi_heco",
      "listCoins_huobi_erc",
      "listCoins_huobi_bsc",
      "listCoins_hotbit_bsc",
      "listCoins_mexc_bsc",
      "listCoins_mexc_polygon",
      "listCoins_matcha_erc",
      "listCoins_kucoin_bsc",
      "listCoins_kucoin_erc",
      "listCoins_okx_erc",
      "listCoins_ftx_erc",
      "currentUser",
    ]),
    setting_url_coin: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.url_coin || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "url_coin", value: newValue });
      },
    },
    setting_url_coin_erc: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.url_coin_erc || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "url_coin_erc", value: newValue });
      },
    },
    setting_url_coin_hotbit_bsc: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.url_coin_hotbit_bsc || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "url_coin_hotbit_bsc", value: newValue });
      },
    },
    setting_url_coin_mexc_bsc: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.url_coin_mexc_bsc || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "url_coin_mexc_bsc", value: newValue });
      },
    },
    setting_url_coin_mexc_polygon: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.url_coin_mexc_polygon || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "url_coin_mexc_polygon", value: newValue });
      },
    },
    setting_url_coin_huobi_heco: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.url_coin_huobi_heco || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "url_coin_huobi_heco", value: newValue });
      },
    },
    setting_busd_address: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.busd_address || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "busd_address", value: newValue });
      },
    },
    setting_usdt_address: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.usdt_address || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "usdt_address", value: newValue });
      },
    },
    setting_husd_address: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.husd_address || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "husd_address", value: newValue });
      },
    },
    setting_timeout_request: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.timeout_request || 15;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "timeout_request", value: newValue });
      },
    },
  },
  components: {},
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.readFile(files[0]);
    },
    readFile(file) {
      let reader = new FileReader();
      reader.onload = e => {
        try {
          this.imported_setting = JSON.parse(CryptoService.decrypt(e.target.result));
          console.log(this.imported_setting);
        } catch (er1) {
          try {
            this.imported_setting = JSON.parse(e.target.result);
          } catch (er2) {
            this.imported_setting = null;
          }
        }
      };
      reader.readAsText(file);
    },
    importSetting() {
      if (this.imported_setting == null) {
        Swal.fire({
          title: "File kosong / Tidak valid!",
          text: "File json untuk import kosong atau format json tidak valid",
          icon: "error",
          heightAuto: false,
        });
      } else {
        let jsonimport = this.imported_setting;
        if (jsonimport.coin == undefined || jsonimport.coin_erc == undefined || jsonimport.settings == undefined) {
          return this.swalFormatSalah("coin dan settings tidak ditemukan");
        }
        let err = 0;
        if (jsonimport.coin !== undefined) {
          jsonimport.coin.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin", item);
            }
          });
        }
        if (jsonimport.coin_erc !== undefined) {
          jsonimport.coin_erc.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_erc", item);
            }
          });
        }
        if (jsonimport.coin_hotbit_bsc !== undefined) {
          jsonimport.coin_hotbit_bsc.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_hotbit_bsc", item);
            }
          });
        }
        if (jsonimport.coin_mexc_bsc !== undefined) {
          jsonimport.coin_mexc_bsc.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_mexc_bsc", item);
            }
          });
        }
        if (jsonimport.coin_mexc_polygon !== undefined) {
          jsonimport.coin_mexc_polygon.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_mexc_polygon", item);
            }
          });
        }
        if (jsonimport.coin_huobi_heco !== undefined) {
          jsonimport.coin_huobi_heco.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_huobi_heco", item);
            }
          });
        }
        if (jsonimport.coin_huobi_erc !== undefined) {
          jsonimport.coin_huobi_erc.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_huobi_erc", item);
            }
          });
        }
        if (jsonimport.coin_huobi_bsc !== undefined) {
          jsonimport.coin_huobi_bsc.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_huobi_bsc", item);
            }
          });
        }
        if (jsonimport.coin_kucoin_bsc !== undefined) {
          jsonimport.coin_kucoin_bsc.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_kucoin_bsc", item);
            }
          });
        }
        if (jsonimport.coin_kucoin_erc !== undefined) {
          jsonimport.coin_kucoin_erc.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_kucoin_erc", item);
            }
          });
        }
        if (jsonimport.coin_okx_erc !== undefined) {
          jsonimport.coin_okx_erc.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_okx_erc", item);
            }
          });
        }
        if (jsonimport.coin_ftx_erc !== undefined) {
          jsonimport.coin_ftx_erc.forEach(item => {
            if (Object.keys(item).length === 0) {
              return false;
            }
            if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
              err++;
              console.log("coin_ftx_erc", item);
            }
          });
        }
        if (err > 0) {
          return this.swalFormatSalah("json coin salah");
        }
        if (jsonimport.settings.url_coin == undefined || jsonimport.settings.busd_address == undefined) {
          return this.swalFormatSalah("json setting salah");
        }
        this.$store.dispatch("setSetting", jsonimport.settings).then(() => {
          this.$store
            .dispatch("importCoin", {
              coin: jsonimport.coin === undefined ? [] : jsonimport.coin,
              coin_erc: jsonimport.coin_erc === undefined ? [] : jsonimport.coin_erc,
              coin_hotbit_bsc: jsonimport.coin_hotbit_bsc === undefined ? [] : jsonimport.coin_hotbit_bsc,
              coin_mexc_bsc: jsonimport.coin_mexc_bsc === undefined ? [] : jsonimport.coin_mexc_bsc,
              coin_mexc_polygon: jsonimport.coin_mexc_polygon === undefined ? [] : jsonimport.coin_mexc_polygon,
              coin_huobi_heco: jsonimport.coin_huobi_heco === undefined ? [] : jsonimport.coin_huobi_heco,
              coin_huobi_erc: jsonimport.coin_huobi_erc === undefined ? [] : jsonimport.coin_huobi_erc,
              coin_huobi_bsc: jsonimport.coin_huobi_bsc === undefined ? [] : jsonimport.coin_huobi_bsc,
              coin_kucoin_bsc: jsonimport.coin_kucoin_bsc === undefined ? [] : jsonimport.coin_kucoin_bsc,
              coin_kucoin_erc: jsonimport.coin_kucoin_erc === undefined ? [] : jsonimport.coin_kucoin_erc,
              coin_okx_erc: jsonimport.coin_okx_erc === undefined ? [] : jsonimport.coin_okx_erc,
              coin_ftx_erc: jsonimport.coin_ftx_erc === undefined ? [] : jsonimport.coin_ftx_erc,
            })
            .then(() => {
              Swal.fire({
                title: "Import sukses!",
                text: "Import setting berhasil",
                icon: "success",
                heightAuto: false,
              });
            });
        });
      }
    },
    swalFormatSalah(msg) {
      Swal.fire({
        title: "Format salah!",
        text: "Format json salah. " + msg,
        icon: "error",
        heightAuto: false,
      });
      return false;
    },
    exportSetting() {
      let payload = {
        settings: this.$store.state.setting.settings,
        coin: this.export_bsc ? this.listCoins : [],
        coin_erc: this.export_erc ? this.listCoins_erc : [],
        coin_huobi_heco: this.export_huobi_heco ? this.listCoins_huobi_heco : [],
        coin_huobi_erc: this.export_huobi_erc ? this.listCoins_huobi_erc : [],
        coin_huobi_bsc: this.export_huobi_bsc ? this.listCoins_huobi_bsc : [],
        coin_hotbit_bsc: this.export_hotbit_bsc ? this.listCoins_hotbit_bsc : [],
        coin_mexc_bsc: this.export_mexc_bsc ? this.listCoins_mexc_bsc : [],
        coin_mexc_polygon: this.export_mexc_polygon ? this.listCoins_mexc_polygon : [],
        coin_kucoin_bsc: this.export_kucoin_bsc ? this.listCoins_kucoin_bsc : [],
        coin_kucoin_erc: this.export_kucoin_erc ? this.listCoins_kucoin_erc : [],
        coin_okx_erc: this.export_okx_erc ? this.listCoins_okx_erc : [],
        coin_ftx_bsc: this.export_ftx_bsc ? this.listCoins_ftx_bsc : [],
      };
      download(CryptoService.encrypt(JSON.stringify(payload)), "wm_setting.json", "text/plain");
    },
    exportSettingRaw() {
      let payload = {
        settings: this.$store.state.setting.settings,
        coin: this.export_bsc ? this.listCoins : [],
        coin_erc: this.export_erc ? this.listCoins_erc : [],
        coin_huobi_heco: this.export_huobi_heco ? this.listCoins_huobi_heco : [],
        coin_huobi_erc: this.export_huobi_erc ? this.listCoins_huobi_erc : [],
        coin_huobi_bsc: this.export_huobi_bsc ? this.listCoins_huobi_bsc : [],
        coin_hotbit_bsc: this.export_hotbit_bsc ? this.listCoins_hotbit_bsc : [],
        coin_mexc_bsc: this.export_mexc_bsc ? this.listCoins_mexc_bsc : [],
        coin_mexc_polygon: this.export_mexc_polygon ? this.listCoins_mexc_polygon : [],
        coin_kucoin_bsc: this.export_kucoin_bsc ? this.listCoins_kucoin_bsc : [],
        coin_kucoin_erc: this.export_kucoin_erc ? this.listCoins_kucoin_erc : [],
        coin_okx_erc: this.export_okx_erc ? this.listCoins_okx_erc : [],
        coin_ftx_erc: this.export_ftx_erc ? this.listCoins_ftx_erc : [],
      };
      download(JSON.stringify(payload), "wm_setting_raw.json", "text/plain");
    },
    formModalCoin(nama_coin) {
      if (nama_coin == null) {
        this.form_coin.edit = false;
        this.form_coin.original = null;
        this.form_coin.nama_coin = "";
        this.form_coin.coin_address = "";
        this.form_coin.desimal = "";
      } else {
        let coin = this.listCoins.find(item => item.nama_coin == nama_coin);
        this.form_coin.edit = true;
        this.form_coin.original = coin.nama_coin;
        this.form_coin.nama_coin = coin.nama_coin;
        this.form_coin.coin_address = coin.coin_address;
        this.form_coin.desimal = coin.desimal;
      }
      this.$refs["modal-form-coin"].show();
    },
    formModalCoin_erc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_erc.edit = false;
        this.form_coin_erc.original = null;
        this.form_coin_erc.nama_coin = "";
        this.form_coin_erc.coin_address = "";
        this.form_coin_erc.desimal = "";
      } else {
        let coin_erc = this.listCoins_erc.find(item => item.nama_coin == nama_coin);
        this.form_coin_erc.edit = true;
        this.form_coin_erc.original = coin_erc.nama_coin;
        this.form_coin_erc.nama_coin = coin_erc.nama_coin;
        this.form_coin_erc.coin_address = coin_erc.coin_address;
        this.form_coin_erc.desimal = coin_erc.desimal;
      }
      this.$refs["modal-form-coin-erc"].show();
    },
    formModalCoin_hotbit_bsc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_hotbit_bsc.edit = false;
        this.form_coin_hotbit_bsc.original = null;
        this.form_coin_hotbit_bsc.nama_coin = "";
        this.form_coin_hotbit_bsc.coin_address = "";
        this.form_coin_hotbit_bsc.desimal = "";
      } else {
        let coin_hotbit_bsc = this.listCoins_hotbit_bsc.find(item => item.nama_coin == nama_coin);
        this.form_coin_hotbit_bsc.edit = true;
        this.form_coin_hotbit_bsc.original = coin_hotbit_bsc.nama_coin;
        this.form_coin_hotbit_bsc.nama_coin = coin_hotbit_bsc.nama_coin;
        this.form_coin_hotbit_bsc.coin_address = coin_hotbit_bsc.coin_address;
        this.form_coin_hotbit_bsc.desimal = coin_hotbit_bsc.desimal;
      }
      this.$refs["modal-form-coin-hotbit_bsc"].show();
    },
    formModalCoin_mexc_bsc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_mexc_bsc.edit = false;
        this.form_coin_mexc_bsc.original = null;
        this.form_coin_mexc_bsc.nama_coin = "";
        this.form_coin_mexc_bsc.coin_address = "";
        this.form_coin_mexc_bsc.desimal = "";
        this.form_coin_mexc_bsc.offset_pnl = "";
      } else {
        let coin_mexc_bsc = this.listCoins_mexc_bsc.find(item => item.nama_coin == nama_coin);
        this.form_coin_mexc_bsc.edit = true;
        this.form_coin_mexc_bsc.original = coin_mexc_bsc.nama_coin;
        this.form_coin_mexc_bsc.nama_coin = coin_mexc_bsc.nama_coin;
        this.form_coin_mexc_bsc.coin_address = coin_mexc_bsc.coin_address;
        this.form_coin_mexc_bsc.desimal = coin_mexc_bsc.desimal;
        this.form_coin_mexc_bsc.offset_pnl = coin_mexc_bsc.offset_pnl === undefined ? 0 : coin_mexc_bsc.offset_pnl;
      }
      this.$refs["modal-form-coin-mexc_bsc"].show();
    },
    formModalCoin_mexc_polygon(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_mexc_polygon.edit = false;
        this.form_coin_mexc_polygon.original = null;
        this.form_coin_mexc_polygon.nama_coin = "";
        this.form_coin_mexc_polygon.coin_address = "";
        this.form_coin_mexc_polygon.desimal = "";
        this.form_coin_mexc_polygon.offset_pnl = "";
      } else {
        let coin_mexc_polygon = this.listCoins_mexc_polygon.find(item => item.nama_coin == nama_coin);
        this.form_coin_mexc_polygon.edit = true;
        this.form_coin_mexc_polygon.original = coin_mexc_polygon.nama_coin;
        this.form_coin_mexc_polygon.nama_coin = coin_mexc_polygon.nama_coin;
        this.form_coin_mexc_polygon.coin_address = coin_mexc_polygon.coin_address;
        this.form_coin_mexc_polygon.desimal = coin_mexc_polygon.desimal;
        this.form_coin_mexc_polygon.offset_pnl = coin_mexc_polygon.offset_pnl === undefined ? 0 : coin_mexc_polygon.offset_pnl;
      }
      this.$refs["modal-form-coin-mexc_polygon"].show();
    },
    formModalCoin_huobi_heco(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_huobi_heco.edit = false;
        this.form_coin_huobi_heco.original = null;
        this.form_coin_huobi_heco.nama_coin = "";
        this.form_coin_huobi_heco.coin_address = "";
        this.form_coin_huobi_heco.desimal = "";
      } else {
        let coin_huobi_heco = this.listCoins_huobi_heco.find(item => item.nama_coin == nama_coin);
        this.form_coin_huobi_heco.edit = true;
        this.form_coin_huobi_heco.original = coin_huobi_heco.nama_coin;
        this.form_coin_huobi_heco.nama_coin = coin_huobi_heco.nama_coin;
        this.form_coin_huobi_heco.coin_address = coin_huobi_heco.coin_address;
        this.form_coin_huobi_heco.desimal = coin_huobi_heco.desimal;
      }
      this.$refs["modal-form-coin-huobi_heco"].show();
    },
    formModalCoin_huobi_erc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_huobi_erc.edit = false;
        this.form_coin_huobi_erc.original = null;
        this.form_coin_huobi_erc.nama_coin = "";
        this.form_coin_huobi_erc.coin_address = "";
        this.form_coin_huobi_erc.desimal = "";
      } else {
        let coin_huobi_erc = this.listCoins_huobi_erc.find(item => item.nama_coin == nama_coin);
        this.form_coin_huobi_erc.edit = true;
        this.form_coin_huobi_erc.original = coin_huobi_erc.nama_coin;
        this.form_coin_huobi_erc.nama_coin = coin_huobi_erc.nama_coin;
        this.form_coin_huobi_erc.coin_address = coin_huobi_erc.coin_address;
        this.form_coin_huobi_erc.desimal = coin_huobi_erc.desimal;
      }
      this.$refs["modal-form-coin-huobi_erc"].show();
    },
    formModalCoin_huobi_bsc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_huobi_bsc.edit = false;
        this.form_coin_huobi_bsc.original = null;
        this.form_coin_huobi_bsc.nama_coin = "";
        this.form_coin_huobi_bsc.coin_address = "";
        this.form_coin_huobi_bsc.desimal = "";
      } else {
        let coin_huobi_bsc = this.listCoins_huobi_bsc.find(item => item.nama_coin == nama_coin);
        this.form_coin_huobi_bsc.edit = true;
        this.form_coin_huobi_bsc.original = coin_huobi_bsc.nama_coin;
        this.form_coin_huobi_bsc.nama_coin = coin_huobi_bsc.nama_coin;
        this.form_coin_huobi_bsc.coin_address = coin_huobi_bsc.coin_address;
        this.form_coin_huobi_bsc.desimal = coin_huobi_bsc.desimal;
      }
      this.$refs["modal-form-coin-huobi_bsc"].show();
    },
    formModalCoin_kucoin_bsc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_kucoin_bsc.edit = false;
        this.form_coin_kucoin_bsc.original = null;
        this.form_coin_kucoin_bsc.nama_coin = "";
        this.form_coin_kucoin_bsc.coin_address = "";
        this.form_coin_kucoin_bsc.desimal = "";
      } else {
        let coin_kucoin_bsc = this.listCoins_kucoin_bsc.find(item => item.nama_coin == nama_coin);
        this.form_coin_kucoin_bsc.edit = true;
        this.form_coin_kucoin_bsc.original = coin_kucoin_bsc.nama_coin;
        this.form_coin_kucoin_bsc.nama_coin = coin_kucoin_bsc.nama_coin;
        this.form_coin_kucoin_bsc.coin_address = coin_kucoin_bsc.coin_address;
        this.form_coin_kucoin_bsc.desimal = coin_kucoin_bsc.desimal;
      }
      this.$refs["modal-form-coin-kucoin_bsc"].show();
    },
    formModalCoin_kucoin_erc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_kucoin_erc.edit = false;
        this.form_coin_kucoin_erc.original = null;
        this.form_coin_kucoin_erc.nama_coin = "";
        this.form_coin_kucoin_erc.coin_address = "";
        this.form_coin_kucoin_erc.desimal = "";
      } else {
        let coin_kucoin_erc = this.listCoins_kucoin_erc.find(item => item.nama_coin == nama_coin);
        this.form_coin_kucoin_erc.edit = true;
        this.form_coin_kucoin_erc.original = coin_kucoin_erc.nama_coin;
        this.form_coin_kucoin_erc.nama_coin = coin_kucoin_erc.nama_coin;
        this.form_coin_kucoin_erc.coin_address = coin_kucoin_erc.coin_address;
        this.form_coin_kucoin_erc.desimal = coin_kucoin_erc.desimal;
      }
      this.$refs["modal-form-coin-kucoin_erc"].show();
    },
    formModalCoin_okx_erc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_okx_erc.edit = false;
        this.form_coin_okx_erc.original = null;
        this.form_coin_okx_erc.nama_coin = "";
        this.form_coin_okx_erc.coin_address = "";
        this.form_coin_okx_erc.desimal = "";
      } else {
        let coin_okx_erc = this.listCoins_okx_erc.find(item => item.nama_coin == nama_coin);
        this.form_coin_okx_erc.edit = true;
        this.form_coin_okx_erc.original = coin_okx_erc.nama_coin;
        this.form_coin_okx_erc.nama_coin = coin_okx_erc.nama_coin;
        this.form_coin_okx_erc.coin_address = coin_okx_erc.coin_address;
        this.form_coin_okx_erc.desimal = coin_okx_erc.desimal;
      }
      this.$refs["modal-form-coin-okx_erc"].show();
    },
    formModalCoin_ftx_erc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_ftx_erc.edit = false;
        this.form_coin_ftx_erc.original = null;
        this.form_coin_ftx_erc.nama_coin = "";
        this.form_coin_ftx_erc.coin_address = "";
        this.form_coin_ftx_erc.desimal = "";
      } else {
        let coin_ftx_erc = this.listCoins_ftx_erc.find(item => item.nama_coin == nama_coin);
        this.form_coin_ftx_erc.edit = true;
        this.form_coin_ftx_erc.original = coin_ftx_erc.nama_coin;
        this.form_coin_ftx_erc.nama_coin = coin_ftx_erc.nama_coin;
        this.form_coin_ftx_erc.coin_address = coin_ftx_erc.coin_address;
        this.form_coin_ftx_erc.desimal = coin_ftx_erc.desimal;
      }
      this.$refs["modal-form-coin-ftx_erc"].show();
    },
    simpanCoin() {
      if (this.form_coin.nama_coin == "" || this.form_coin.coin_address == "" || this.form_coin.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin", this.form_coin).then(() => {
        this.$refs["modal-form-coin"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_erc() {
      if (this.form_coin_erc.nama_coin == "" || this.form_coin_erc.coin_address == "" || this.form_coin_erc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_erc", this.form_coin_erc).then(() => {
        this.$refs["modal-form-coin-erc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_hotbit_bsc() {
      if (this.form_coin_hotbit_bsc.nama_coin == "" || this.form_coin_hotbit_bsc.coin_address == "" || this.form_coin_hotbit_bsc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_hotbit_bsc", this.form_coin_hotbit_bsc).then(() => {
        this.$refs["modal-form-coin-hotbit_bsc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_mexc_bsc() {
      if (this.form_coin_mexc_bsc.nama_coin == "" || this.form_coin_mexc_bsc.coin_address == "" || this.form_coin_mexc_bsc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_mexc_bsc", this.form_coin_mexc_bsc).then(() => {
        this.$refs["modal-form-coin-mexc_bsc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_mexc_polygon() {
      if (this.form_coin_mexc_polygon.nama_coin == "" || this.form_coin_mexc_polygon.coin_address == "" || this.form_coin_mexc_polygon.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_mexc_polygon", this.form_coin_mexc_polygon).then(() => {
        this.$refs["modal-form-coin-mexc_polygon"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_huobi_heco() {
      if (this.form_coin_huobi_heco.nama_coin == "" || this.form_coin_huobi_heco.coin_address == "" || this.form_coin_huobi_heco.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_huobi_heco", this.form_coin_huobi_heco).then(() => {
        this.$refs["modal-form-coin-huobi_heco"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_huobi_erc() {
      if (this.form_coin_huobi_erc.nama_coin == "" || this.form_coin_huobi_erc.coin_address == "" || this.form_coin_huobi_erc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_huobi_erc", this.form_coin_huobi_erc).then(() => {
        console.log(this.form_coin_huobi_erc);
        this.$refs["modal-form-coin-huobi_erc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_huobi_bsc() {
      if (this.form_coin_huobi_bsc.nama_coin == "" || this.form_coin_huobi_bsc.coin_address == "" || this.form_coin_huobi_bsc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_huobi_bsc", this.form_coin_huobi_bsc).then(() => {
        console.log(this.form_coin_huobi_bsc);
        this.$refs["modal-form-coin-huobi_bsc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_kucoin_bsc() {
      if (this.form_coin_kucoin_bsc.nama_coin == "" || this.form_coin_kucoin_bsc.coin_address == "" || this.form_coin_kucoin_bsc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_kucoin_bsc", this.form_coin_kucoin_bsc).then(() => {
        console.log(this.form_coin_kucoin_bsc);
        this.$refs["modal-form-coin-kucoin_bsc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_kucoin_erc() {
      if (this.form_coin_kucoin_erc.nama_coin == "" || this.form_coin_kucoin_erc.coin_address == "" || this.form_coin_kucoin_erc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_kucoin_erc", this.form_coin_kucoin_erc).then(() => {
        console.log(this.form_coin_kucoin_erc);
        this.$refs["modal-form-coin-kucoin_erc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_okx_erc() {
      if (this.form_coin_okx_erc.nama_coin == "" || this.form_coin_okx_erc.coin_address == "" || this.form_coin_okx_erc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_okx_erc", this.form_coin_okx_erc).then(() => {
        console.log(this.form_coin_okx_erc);
        this.$refs["modal-form-coin-okx_erc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_ftx_erc() {
      if (this.form_coin_ftx_erc.nama_coin == "" || this.form_coin_ftx_erc.coin_address == "" || this.form_coin_ftx_erc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_ftx_erc", this.form_coin_ftx_erc).then(() => {
        console.log(this.form_coin_ftx_erc);
        this.$refs["modal-form-coin-ftx_erc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    hapusCoin(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_erc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_erc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_hotbit_bsc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_hotbit_bsc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_mexc_bsc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_mexc_bsc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_mexc_polygon(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_mexc_polygon", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_huobi_heco(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_huobi_heco", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_huobi_erc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_huobi_erc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_huobi_bsc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_huobi_bsc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_kucoin_bsc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_kucoin_bsc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_kucoin_erc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_kucoin_erc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_okx_erc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_okx_erc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_ftx_erc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_ftx_erc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Market Watch" }, { title: "Setting" }]);
    this.is_admin = this.currentUser.level_akses == 1;
  },
};
</script>
